<template>
  <b-row>
    <b-col cols="12">
      <b-card-actions ref="list" title="" action-refresh @refresh="refresh">

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <export-excel
                  :data="rows"
                  :columns="excel_columns"
                  :file-name="'document_sections'"
                  :file-type="'xlsx'"
                  :sheet-name="'sheetname'"
              >
                <feather-icon icon="DownloadIcon"/>
              </export-excel>

              <b-button
                  variant="primary"
                  class="button-inline-spacing-2"

                  @click="newDepartment()"
                  :disabled="editDisabled"
              >
                <span class="text-nowrap">{{ $t("Add department") }}</span>
              </b-button>
            </b-col>

            <!-- Search -->
            <b-col
                cols="12"
                md="6"
            >

              <b-form-input
                  v-model="searchTerm"
                  :placeholder="$t('message.SearchPlaceholder')"
                  type="text"
                  class="d-inline-block"
              />
            </b-col>
          </b-row>

        </div>


        <!-- alert -->
        <!--b-alert
          variant="primary"
          show
        >
          <div class="alert-body">
          </div>
        </b-alert-->

        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">


        </div>

        <!-- table -->
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
            :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
            :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        >

          <!-- Slot: Table Column -->
          <template
              slot="table-column"
              slot-scope="props"
          >

        <span>
           {{ $t(props.column.label) }}
        </span>
          </template>

          <!-- Slot: Table Row -->
          <template
              slot="table-row"
              slot-scope="props"
          >


            <!-- Column: Name -->
            <span
                v-if="props.column.field === 'name'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.name }}</span>
        </span>


            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'" class="button-inline-spacing">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="edit(props.row)"

          >
      <feather-icon icon="Edit2Icon"/>
    </b-button>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteDepartment(props.row)"
                :disabled="editDisabled"
            >
      <feather-icon icon="TrashIcon"/>
    </b-button>


        </span>

            <span v-else class="text-nowrap align-middle">


        </span>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >

            <div class="d-flex justify-content-between flex-wrap">


              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10','15']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{
                    $t('message.pageText2')
                  }} </span>
              </div>
              <div>

                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>

                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>


      </b-card-actions>

      <b-modal
          id="modal-department"
          cancel-variant="outline-secondary"
          :ok-title="$t('Save')"
          ok-s
          :cancel-title="$t('Cancel')"
          centered
          :title="$t('New department')"
          @ok="createDepartment"
      >
        <b-form>
          <b-form-group>
            <label for="new_department_name">{{ $t('New department') }}:</label>
            <b-form-input
                id="new_department"
                type="text"
                v-model="new_department_name"
                :placeholder="$t('Name')"
            />
          </b-form-group>

        </b-form>
      </b-modal>

      <b-modal
          id="modal-update-department"
          cancel-variant="outline-secondary"
          :ok-title="$t('Save')"
          ok-s
          :cancel-title="$t('Cancel')"
          centered
          :title="$t('Edit department')"
          @ok="updateDepartment"
      >
        <b-form>
          <b-form-group>
            <label for="new_department_name">{{ $t('New department name') }}:</label>
            <b-form-input
                id="new_department"
                type="text"
                v-model="new_department_name"
                :placeholder="$t('Name')"
            />
          </b-form-group>

        </b-form>
      </b-modal>
    </b-col>
  </b-row>
</template>


<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {BCardActions} from "@core/components/b-card-actions";
import {
  BAvatar, BBadge, BPagination,BForm, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BAlert, BDropdown, BDropdownItem, BRow, BCol
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import {codeI18n} from './code'
import useJwt from '@/auth/jwt/useJwt'
import UserService from "@/basic/userService";
import EquipmentService from "@/basic/equipmentService";
import Ripple from 'vue-ripple-directive'

import {BButton} from "bootstrap-vue";
import ExportExcel from "@/views/components/excel-export/ExportExcel";

import Vue from "vue";
import i18n from "@/libs/i18n";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ability from "@/libs/acl/ability";
import EmployeeService from "@/basic/employeeService";

Vue.use(ExportExcel);

export default {
  components: {
    BCardCode,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 15,
      codeI18n,
      dir: false,
      editDisabled: !ability.can("manage", "Basic HR"),
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Action',
          field: 'action',
        },

      ],
      excel_columns: [
        {
          label: i18n.t('Name'),
          field: 'name',
        },


      ],
      rows: [],
      searchTerm: '',
      new_department_name:'',
      edit_department_id:0,

    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    refresh() {
      console.log("refresh")
      this.$refs["list"].showLoading = true


      let company_id = store.state.userCompany.id;
      const employeeService = new EmployeeService(useJwt)

      employeeService.departments(company_id, {}).then(response => {
        this.rows = response.data.data.departments
        console.log('rows', this.rows)
        this.$refs["list"].showLoading = false

      })

    },


    createDepartment() {
      console.log('new department name', this.new_department_name)
      const employeeService = new EmployeeService(useJwt)
      let company_id = store.state.userCompany.id;
      this.$refs["list"].showLoading = true

      const data = {
        employeeDepartment: {name: this.new_department_name, company_id: company_id}
      };

      employeeService.update_department('new', data).then(response => {
        console.log("response", response)
        this.rows = response.data.data.departments;
        //this.department_new_id = response.data.data.department.id;
        //this.refresh();
        this.$refs["list"].showLoading = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: i18n.t('Department'),
            icon: 'AlertIcon',
            variant: 'success',
            text: i18n.t('Department saved with success'),
          },
        })
      })
      this.new_department_name = ""
    },

    updateDepartment() {
      console.log('updateDepartment new department name', this.new_department_name)
      console.log('updateDepartment department id', this.edit_department_id)
      const employeeService = new EmployeeService(useJwt)
      let company_id = store.state.userCompany.id;
      this.$refs["list"].showLoading = true

      const data = {
        employeeDepartment: {name: this.new_department_name, company_id: company_id}
      };

      employeeService.update_department(this.edit_department_id, data).then(response => {
        console.log("response", response)
        this.rows = response.data.data.departments;
        //this.department_new_id = response.data.data.department.id;
        //this.refresh();
        this.$refs["list"].showLoading = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: i18n.t('Department'),
            icon: 'AlertIcon',
            variant: 'success',
            text: i18n.t('Department saved with success'),
          },
        })
      })
      this.new_department_name = ""
    },
    edit(row) {
      this.edit_department_id = row.id
      this.new_department_name = row.name
      this.$bvModal.show('modal-update-department')
    },

    newDepartment() {
      this.edit_department_id = 0
      this.new_department_name = ''
      this.$bvModal.show('modal-department')
    },
    deleteDepartment(row) {
      console.log('delete')
      let confirmed = false;
      let message = i18n.t('You are deleting the department') + ": \"" + row.name + "\". " + i18n.t("Are you sure?");

      this.$bvModal
          .msgBoxConfirm(message, {
            title: i18n.t('Confirmation Required'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: i18n.t('Proceed'),
            cancelTitle: i18n.t('Cancel'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            confirmed = value

            if (confirmed) {
              const employeeService = new EmployeeService(useJwt)
              employeeService.delete_department(row.id).then(response => {
                console.log('deletion response', response)

                if (response.data.status ==='success'){
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: i18n.t('Department deleted'),
                      icon: 'AlertIcon',
                      variant: 'success',
                      text: i18n.t('Department deleted with success'),
                    },
                  })
                }
                else{
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: i18n.t('Department not deleted'),
                      icon: 'AlertIcon',
                      variant: 'danger',
                      text: i18n.t(response.data.message),
                    },
                  })
                }

                this.refresh();
              })
            }
          })

    },
    list_documents(id) {
      this.$router.push({name: 'documents', params: {document_section_id: id}})
    },
  },
  mounted() {
    console.log('mounted')
    this.refresh()


    this.$refs["list"].showLoading = true

    this.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'selected_company') {
        console.log(`Updating selected_company to ${state.userCompany.id}`);
        this.refresh();
      }
    });
    const equipmentService = new EquipmentService(useJwt)


    //this.$http.get('/good-table/basic')
    //.then(res => { this.rows = res.data })
  },
  beforeDestroy() {
    this.unsubscribe();
  }
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
